
import EditAttributeModal from "@/components/modals/forms/EditAttributeModal.vue";
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import AddRuleEngineModal from "@/components/modals/forms/AddRuleEngineModal.vue";
import DeleteUserModal from "@/components/modals/forms/DeleteUserModal.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Globals from "@/views/common/Globals.vue";
import { ElNotification } from "element-plus";
import { ElLoading } from "element-plus";
import router from "@/router";
import store from "@/store";

interface ICustomer {
  attributeId: number;
  rulename: string;
  minval: string;
  maxval: string;
  values: string;
  productIds: string;
}

export default defineComponent({
  name: "UserManagement",
  mixins: [Globals],
  components: {
    Datatable,
    AddRuleEngineModal,
    EditAttributeModal,
    DeleteUserModal,
  },

  data() {
    return {
      attributedata: "",
      ruledata: {
    "reqId": "7d5b196f-f205-4883-9f43-57161496432f",
    "errors": [],
    "data": [
        {
            "attributeId": "age",
            "rulename": "rule1",
            "minval": "25",
            "maxval": "40",
            "values": "",
            "productIds": '["T51","T54","T50","T52"]',
        },
        {
            "attributeId": "age",
            "rulename": "rule2",
            "minval": "46",
            "maxval": "55",
            "values": "",
            "productIds": '["T53","T55"]',
        },
        {
            "attributeId": "pin",
            "rulename": "rule3",
            "minval": "",
            "maxval": "",
            "values": "400034",
            "productIds": '["T51"]',
        },
        {
            "attributeId": "pin",
            "rulename": "rule4",
            "minval": "",
            "maxval": "",
            "values": "400101",
            "productIds": '["T51","T53"]',
        },
        {
            "attributeId": "channel",
            "rulename": "rule5",
            "minval": "",
            "maxval": "",
            "values": "Web",
            "productIds": '["T53","T58","T59"]',
        },
        {
            "attributeId": "sumAssured",
            "rulename": "rule6",
            "minval": "100000",
            "maxval": "500000",
            "values": "",
            "productIds": '["T51","T53","T54","T58","T59"]',
        },
        {
            "attributeId": "journeyType",
            "rulename": "rule7",
            "minval": "",
            "maxval": "",
            "values": "Personal Loan",
            "productIds": '["T53","T55"]',
        }
    ]
},
      group: "",
      userdata: [],
      render: false,
      useremail: "",
      username: "",
      userrole: "",
      mobile: "",
      editflag: false,
      orgId: "",
      searchUserKey: "",
      active: false,
      initCust: [] as ICustomer[],
      search: "",
      request: true,
      // visible:true,
      searchFlag: true,
      buttonActive: false,
      groupData: [] as any,
      AllData: [] as any,
      Enable: true,
      orgRole: this.getOrgRole(),
      
    };
  },

  methods: {
    passdata(){
      (this.$refs["addRuleForm"] as any).callUpdate(
        this.attributedata
      );
    },
    checkRole() {
      if (this.orgRole == "consumer") this.orgRole = "C";
      else if (this.orgRole == "manufacturer") this.orgRole = "M";
      else if (this.orgRole == "api_provider") this.orgRole = "P";
      else if (this.orgRole == "1SB") this.orgRole = "P";
      return this.orgRole;
    },

    searchItems() {
      this.userdata["data"].splice(
        0,
        this.userdata["data"].length,
        ...this.initCust
      );

      if (this.search !== "") {
        let results = [] as ICustomer[];
        for (let j = 0; j < this.userdata["data"].length; j++) {
          if (this.searchingFunc(this.userdata["data"][j], this.search)) {
            results.push(this.userdata["data"][j]);
          }
        }
        this.userdata["data"].splice(
          0,
          this.userdata["data"].length,
          ...results
        );
        if (this.userdata["data"].length == 0 && this.searchFlag == true) {
          this.searchFlag = false;
          ElNotification({
            title: "Warning",
            message: "No Records Found!",
            type: "warning",
            duration: 2000,
            position: "top-right",
          });
        } else if (this.userdata["data"].length != 0) {
          this.searchFlag = true;
        }
      }
    },
    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    },
    OrgDetails() {
      ApiService.get("/allOrganizationsId")
        .then((data) => {
          // loading.close()
          this.AllData = JSON.parse(JSON.stringify(data.data.data));
          console.log(this.AllData, "allData");
        })
        .catch((error) => {
          console.log(error);
          //  loading.close()
          //  if(error.response.status===401)
          //  {
          //     router.push({ name: "sign-in" });
          //  }
        });
    },
    displayData() {
      this.request = false;
      this.userdata = JSON.parse(JSON.stringify(this.ruledata));

      if (this.userdata.length != 0) {
        this.render = true;
      }
      this.initCust.splice(
        0,
        this.userdata["data"].length,
        ...this.userdata["data"]
      );
    },
    getuser() {
      this.displayData();
    },
    // getuser() {
    //   var link = "";
    //   if (this.group == "") {
    //     link = "/user?organizationId=" + this.globalData.organizationId;
    //   } else {
    //     link = "user?organizationId=" + this.group;
    //   }
    //   this.render = false;
    //   const loading = ElLoading.service({
    //     lock: true,
    //     text: "Loading",
    //     background: "rgba(0, 0, 0, 0.7)",
    //   });
    //   ApiService.get(link)
    //     .then((data) => {
    //       loading.close();
    //       this.displayData(data.data);
    //     })
    //     .catch((error) => {
    //       loading.close();
    //       ElNotification({
    //         title: "Warning",
    //         //message: "No APIs exists with '" + this.category + "' category",
    //         message: JSON.stringify(
    //           error.response.data["errors"][0]["errorMessage"]
    //         ),
    //         type: "warning",
    //         duration: 2000,
    //         position: "top-right",
    //       });
    //       //  if(error.response.status===401)
    //       //  {
    //       //     router.push({ name: "sign-in" });
    //       //  }
    //     });
    // },

    edituser(name, type, isrange, values) {
      (this.$refs["editAttributeForm"] as any).callUpdate(
        name,
        type,
        isrange,
        values
      );
      this.buttonActive = true;
    },

    // enablefunction(Email, Status) {
    //   (this.$refs["deleteUserForm"] as any).callUpdate(Email, Status);
    // },
    ChangeT(test) {
      this.getuser();
    },

    buttonReset(reset) {
      this.buttonActive = false;
    },
    goBack() {
      this.group = "";
      this.getuser();
      if (this.getOrgRole() === "1SB") {
        this.OrgDetails();
      }
    },
  },

  mounted() {
    if(sessionStorage.getItem("attributedata")){
      this.attributedata = JSON.parse(sessionStorage.getItem("attributedata"));
    }
    if(sessionStorage.getItem("ruledata"))
    {
      this.ruledata = JSON.parse(sessionStorage.getItem("ruledata"));
    }
    else
    {
      sessionStorage.setItem("ruledata", JSON.stringify(this.ruledata));
    }
    this.getuser();
    if (this.getOrgRole() === "1SB") {
      this.OrgDetails();
    }
  },

  setup() {
    const formData = ref({
      application: "",
      organizationType: "",
      organizationId: "",
      data: {
        email: "",
        enable: true,
      },
    });

    const checkedCustomers = ref([]);
    const tableHeader = ref([
      {
        name: "Rule Name",
        key: "rulename",
        sortable: true,
      },
      // {
      //   name: "Attribute_id",
      //   key: "attributeId",
      //   sortable: true,
      // },
      // {
      //   name: "Minimum",
      //   key: "minval",
      //   sortable: true,
      // },
      // {
      //   name: "Maximun",
      //   key: "maxval",
      //   sortable: true,
      // },
      // {
      //   name: "Values",
      //   key: "values",
      //   sortable: true,
      // },
      {
        name: "productIds",
        key: "productIds",
        sortable: true,
      },

      {
        name: "Action",
        key: "actions",
      },
      {
        name: "Delete User",
        key: "delete",
      },
    ]);

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Rules", ["Manage"]);
    });

    return {
      tableHeader,
      checkedCustomers,
      formData,
    };
  },
});

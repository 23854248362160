
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
// import { __values } from "tslib";
import ApiService from "@/core/services/ApiService";
import Globals from "@/views/common/Globals.vue";
import { Field, Form } from "vee-validate";
import { NAME, PHONE } from "@/helper/globalConst";
import { ElNotification } from "element-plus";
import * as Yup from "yup";

export default defineComponent({
  name: "AddUserModel",
  mixins: [Globals],

  components: {
    Form,
    Field,
  },
  props: {
    render: Boolean,
  },
  data() {
    return {
      inputs: [
        { match: "", attributeId: "", minimum: "", maximum: "", values: "" },
      ],
      attributedata: "",
      // {
      //   reqId: "baa3fd74-be19-4a1f-b36f-204a38431b44",
      //   errors: [],
      //   data: [
      //     {
      //       attributeId: "age",
      //       name: "Age",
      //       type: "Integer",
      //       range: "True",
      //       values: "1-99",
      //     },
      //     {
      //       attributeId: "pin",
      //       name: "Pin Code",
      //       type: "Integer",
      //       range: "False",
      //       values: "",
      //     },
      //     {
      //       attributeId: "channel",
      //       name: "Channel",
      //       type: "Enum",
      //       range: "False",
      //       values: "Web, Mobile, Mobile_Web, Chatbot",
      //     },
      //     {
      //       attributeId: "sumAssured",
      //       name: "Sum Assured",
      //       type: "Integer",
      //       range: "True",
      //       values: "Web, Mobile, Mobile_Web, Chatbot",
      //     },
      //     {
      //       attributeId: "journeyType",
      //       name: "Journey Type",
      //       type: "Enum",
      //       range: "False",
      //       values: "Salaried, Unemployed, Self-Employed",
      //     },
      //     {
      //       attributeId: "workExperience",
      //       name: "Work Experience",
      //       type: "Integer",
      //       range: "True",
      //       values: "",
      //     },
      //     {
      //       attributeId: "loanAmount",
      //       name: "Loan Amount",
      //       type: "Integer",
      //       range: "True",
      //       values: "",
      //     },
      //     {
      //       attributeId: "income",
      //       name: "Income",
      //       type: "Integer",
      //       range: "True",
      //       values: "",
      //     },
      //   ],
      // },
      requestbody: null,
      orgId: "",
      formflag: false,
      role: this.getOrgRole(),
      flag: false,
      reset: false,
      loading: false,
      buttonActive: false,
      category: "Insurance",
      group: "",
      productDetails: [] as any,
      selectedProducts: [] as any,
      products: {
        "Wealth Management": {
          "Fixed Deposit": {},
          "PMS AIF": {},
        },
        Insurance: {
          "Group Health": {},
          "Group Term": {
            GP1: false,
          },
          "Health Insurance": {},
          "Life Savings": {
            E21: false,
            E28: false,
            E29: false,
          },
          "Life Term": {
            T40: false,
            T41: false,
            T43: false,
            T50: false,
            T51: false,
          },
        },
      },
    };
  },

  methods: {
    callUpdate(attributedata){
      this.attributedata = attributedata;
    },
    add() {
      this.inputs.push({
        match: "",
        attributeId: "",
        minimum: "",
        maximum: "",
        values: "",
      });
    },

    remove(index) {
      this.inputs.splice(index, 1);
    },    
    setData() {
      this.productDetails = this.products[this.category][this.group];
    },
    clearData() {
      this.productDetails = [];
    },
    resetform() {
      this.inputs = [];
      this.inputs.push({
        match: "",
        attributeId: "",
        minimum: "",
        maximum: "",
        values: "",
      });
      this.productDetails = [];
      this.selectedProducts = [];
      this.category = "Insurance";
      this.group = "";
      (this.$refs["resetForm"] as any).click();
    },
    showData(check, item) {
      if (check == true && this.selectedProducts.indexOf(item) == -1) {
        this.selectedProducts.push(item);
      } else if (check == false && this.selectedProducts.indexOf(item) > -1) {
        this.selectedProducts.splice(this.selectedProducts.indexOf(item), 1);
      }
    },
    checkRole() {
      if (this.role == "consumer") this.role = "C";
      else if (this.role == "manufacturer") this.role = "M";
      else if (this.role == "api_provider") this.role = "P";
      else if (this.role == "1SB") this.role = "P";
      return this.role;
    },
    addData(formData) {
      console.log(this.inputs);
      formData.data.conditions = this.inputs;
      formData.data.productIds = this.selectedProducts;
      let ruledata;
      ruledata = JSON.parse(sessionStorage.getItem("ruledata"));
      ruledata.data.push(formData.data);
      sessionStorage.setItem("ruledata", JSON.stringify(ruledata));
      // this.loading = true;
      // this.buttonActive = true;
      // formData.organizationType = this.checkRole();
      // formData.organizationId = this.globalData.organizationId;
      // formData.data.orgRole = this.getOrgRole();
      // formData.data.organizationId = this.globalData.organizationId;

      // ApiService.post("/user", formData)
      //   .then((data) => {
      //     (this.loading = false),
      //       (this.buttonActive = false),
      (this.$refs["resetForm"] as any).click();
      window.location.reload(true);
      hideModal(this.addRuleForm);
      this.$emit("changeTitle", "false");
      ElNotification({
        title: "Success",
        message: "New Rule added!",
        type: "success",
        duration: 2000,
        position: "top-right",
      });
      // })
      // .catch((error) => {
      //   this.loading = false;
      //   this.buttonActive = false;
      //   ElNotification({
      //     title: "Error",
      //     message: JSON.stringify(
      //       error.response.data["errors"][0]["errorMessage"]
      //     ),
      //     type: "error",
      //     duration: 2000,
      //     position: "top-right",
      //   });
      // });
    },
  },

  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const addRuleForm = ref<null | HTMLElement>(null);
    // const loading = ref<boolean>(false);

    const formData = ref({
      application: "",
      organizationType: "",
      organizationId: "",
      data: {
        rulename: "",
        // attributeId: "",
        // minval: "",
        // maxval: "",
        // values: "",
        conditions: [],
        productIds: "",
      },
    });

    const formValidation = Yup.object().shape({
      rulename: Yup.string().required().label("Rule Name"),
      // attributeId: Yup.string()
      //   .max(8, "Attribute Id can be 8 characters only")
      //   .matches(NAME, "Attribute Id should be only characters")
      //   .required()
      //   .label("Attribute Id"),
      // minval: Yup.string().label("Minimun"),
      // maxval: Yup.string().label("Maximum"),
      // values: Yup.string().label("Values"),
      // condition: Yup.string().required().label("Condition"),
      // productIds: Yup.string().required().label("Product Ids"),
      // productCategory: Yup.string().required().label("Product Category"),
      // productGroup: Yup.string().required().label("Product Group"),
    });

    return {
      formData,
      formValidation,
      formRef,
      addRuleForm,
    };
  },
});
